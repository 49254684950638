import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import Imgix from 'react-imgix'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import { ABSpinner, useErrorService } from '@abroad/components'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import '../../styles/customSwiper.scss'
import API from '../../utils/API'

import { useResolutionService } from '../../hooks'

SwiperCore.use([Navigation, Pagination])

const QuestsFaculty = ({ questId }) => {
  const Error = useErrorService()
  const [isLoading, setIsLoading] = useState(true)
  const [faculties, setFaculties] = useState([])
  const { isMDScreen } = useResolutionService()

  useEffect(() => {
    const getQuestsFaculties = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.quests.questsFaculties(questId)
        setFaculties(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getQuestsFaculties()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  return (
    <>
      <div>
        {isLoading ? (
          <ABSpinner />
        ) : (
          <>
            {faculties.length > 0 && (
              <div className='mt-15px'>
                <div className='s3'>Get to Know Your Faculty</div>
                <Row className='align-items-center mb-md-35px position-relative'>
                  <Swiper
                    slidesPerView={3}
                    navigation
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      425: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1024: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1440: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      2560: {
                        slidesPerView: 3,
                        spaceBetween: 80,
                      },
                    }}
                    className='custom-swiper'
                    style={{ position: 'unset' }}>
                    {faculties.map((item) => {
                      const imageUrl = `${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${item.imageFileName}`
                      return (
                        <SwiperSlide key={item.id}>
                          {' '}
                          <div style={{ width: '100%' }}>
                            <Imgix
                              className='border-radius-10 mt-4 lazyload imgix-object-cover'
                              imgixParams={{
                                fit: 'crop',
                                crop: 'faces',
                                ar: isMDScreen ? '2:1' : '5:3',
                              }}
                              src={imageUrl}
                              width={'100%'}
                              alt={`${item.name}'s image`}
                              attributeConfig={{
                                src: 'data-src',
                                srcSet: 'data-srcset',
                                sizes: 'data-sizes',
                              }}
                              htmlAttributes={{
                                src: `${imageUrl}?ar=${
                                  isMDScreen ? '2:1' : '5:3'
                                }&fit=crop&crop=faces&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                              }}
                            />
                            <h4 className='s9a pt-16px font-saffron-700'>
                              {item.expertise}
                            </h4>
                            <h2 className='s2'>{item.name}</h2>
                            <p className='s6 pt-16px'>
                              {item.description}
                            </p>{' '}
                          </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </Row>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default QuestsFaculty
