import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import {
  NavTabs,
  ABSpinner,
  useErrorService,
  useUserService,
} from '@abroad/components'
import QuestsLearningResourcesAudio from './QuestsLearningResourcesAudio'
import API from '../../utils/API'
import QuestsLearningResourcesVideo from './QuestsLearningResourcesVideo'
import QuestsLearningResourcesBlog from './QuestsLearningResourcesBlog'
import ReadLearningEvent from '../../constants/events'
import ListenLearningEvent from '../../constants/events'
import WatchLearningEvent from '../../constants/events'

const QuestsLearningResources = ({ questId }) => {
  const Error = useErrorService()
  const location = useLocation()
  const { user } = useUserService()
  const [isLoading, setIsLoading] = useState(true)
  const [blogs, setBlogs] = useState([])
  const [videos, setVideos] = useState([])
  const [active, setActive] = useState('Listen')
  const resourceCategories = ['Listen', 'Watch', 'Read']
  useEffect(() => {
    const getAllBlogCategories = async () => {
      try {
        const { data } = await API.quests.getQuestMedias(questId)
        if (data) {
          setVideos(data.videos || [])
          setBlogs(data.blogs || [])
          setIsLoading(false)
        } else {
          setBlogs([])
          setVideos([])
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    getAllBlogCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questId])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const category = queryParams.get('category')
    if (category && resourceCategories.includes(category)) {
      setActive(category)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const gtmLearningResourceRead = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: ReadLearningEvent.event.readLearningResource,
        eventProps: {
          category: ReadLearningEvent.category.readLearningResourceCategory,
          action: 'User click on learning resource read tab',
          label: 'click on learning resource read tab',
          value: `User click on learning resource read tab questId-${questId}`,
          userId: user._id,
        },
      },
    })
  }

  const gtmLearningResourceListen = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: ListenLearningEvent.event.listenLearningResource,
        eventProps: {
          category: ListenLearningEvent.category.listenLearningResourceCategory,
          action: 'User click on learning resource listen tab',
          label: 'click on learning resource listen tab',
          value: `User click on learning resource listen tab questId-${questId}`,
          userId: user._id,
        },
      },
    })
  }

  const gtmLearningResourceWatch = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: WatchLearningEvent.event.watchLearningResource,
        eventProps: {
          category: WatchLearningEvent.category.watchLearningResourceCategory,
          action: 'User click on learning resource watch tab',
          label: 'click on learning resource watch tab',
          value: `User click on learning resource watch tab questId-${questId}`,
          userId: user._id,
        },
      },
    })
  }

  return (
    <>
      <h1 className='s3 mt-64px mb-24px'>
        Learning Resources to Prep for Quest
      </h1>

      <NavTabs
        pages={resourceCategories.map((cat) => ({
          path: `/your-quest?category=${cat}`,
          name: cat,
        }))}
        pathname={location?.pathname}
        hash={location?.hash}
        NavLink={({ to, children }) => (
          <NavLink
            to={to}
            onClick={() => {
              setActive(children)
              if (children === 'Listen') {
                gtmLearningResourceListen()
              } else if (children === 'Watch') {
                gtmLearningResourceWatch()
              } else if (children === 'Read') {
                gtmLearningResourceRead()
              }
            }}
            className={active === children ? 'active' : ''}>
            {children}
          </NavLink>
        )}
      />

      {isLoading ? (
        <ABSpinner />
      ) : (
        <>
          {active === 'Watch' && videos.length === 0 && (
            <h3 className='mt-32px text-center s5'>
              There are no videos available at the moment. Please check back
              later!
            </h3>
          )}
          {active === 'Read' && blogs.length === 0 && (
            <h3 className='mt-32px text-center s5'>
              There are no blogs available at the moment. Please check back
              later!
            </h3>
          )}
          {active === 'Listen' && (
            <QuestsLearningResourcesAudio questId={questId} />
          )}
          {active === 'Watch' && videos.length > 0 && (
            <QuestsLearningResourcesVideo category={videos} questId={questId} />
          )}
          {active === 'Read' && blogs.length > 0 && (
            <QuestsLearningResourcesBlog category={blogs} questId={questId} />
          )}
        </>
      )}
    </>
  )
}

export default QuestsLearningResources
