import React from 'react'
import { useHistory } from 'react-router-dom'
import Imgix from 'react-imgix'
import { useResolutionService } from '../../hooks'

const QuestsBlogCard = ({ blog, questId }) => {
  const { isMDScreen } = useResolutionService()
  const history = useHistory()
  const getDescription = (subTitle) => {
    return subTitle.length > 550 ? subTitle.slice(0, 550) + '...' : subTitle
  }
  const handleBlogClick = (blogId) => {
    history.push(`/your-quest/${blogId}/blog-detail`)
  }

  return (
    <>
      <div onClick={() => handleBlogClick(blog?._id)}>
        <div className='row g-0 program-wrp cursor-pointer border-radius-5 p-32px custom-gray-bg-100 mt-24px'>
          <div className='col col-lg-4 col-12'>
            <div className='position-relative overflow-hidden border-radius-5'>
              <Imgix
                className='border-radius-5 img-cover w-100 program-img-zoom lazyload'
                imgixParams={{
                  fit: 'crop',
                  crop: 'edges',
                  ar: isMDScreen ? '3:1' : '4:5',
                }}
                src={`${process.env.REACT_APP_IMG_SOURCE}/blogs/${blog?.image}`}
                alt='image'
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
                htmlAttributes={{
                  src: `${process.env.REACT_APP_IMG_SOURCE}/blogs/${
                    blog?.image
                  }?ar=${
                    isMDScreen ? '3:1' : '4:5'
                  }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`,
                }}
              />

              <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10'></div>
            </div>
          </div>
          <div className='col col-lg-8 col-12 pl-lg-32px pt-lg-0 pt-3 d-flex flex-column justify-content-center'>
            <div className='s11 font-16px font-saffron-700 d-flex align-items-center'>
              <span className='text-capitalize'>Author:</span>
              <span style={{ margin: '2px' }}>{blog?.author}</span>
            </div>
            <div className='s2 mt-1'>
              <span className='fst-italic'>{blog?.title}</span>
            </div>
            <pre
              className={'s7 mb-0 mt-15px pre'}
              dangerouslySetInnerHTML={{
                __html: getDescription(blog?.subTitle),
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestsBlogCard
