import React, { useState } from 'react'
import Imgix from 'react-imgix'
import { useResolutionService } from '@abroad/components'
import { ModuleMiniDetails } from '../breakthrough'

const QuestDetail = ({ questHomeId, questHome }) => {
  const { isMDScreen } = useResolutionService()
  const [expanded, setExpanded] = useState(false)

  const getDay = (dateString) => {
    const date = new Date(dateString)
    return date.getUTCDate()
  }

  const startDay = getDay(questHome?.startDate)
  const endDay = getDay(questHome?.endDate)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const getDescription = () => {
    if (expanded) {
      return questHome?.description
    } else {
      return questHome?.description.length > 550
        ? questHome?.description.slice(0, 550) + '...'
        : questHome?.description
    }
  }

  return (
    <>
      <Imgix
        className='border-radius-10 mt-32px lazyload imgix-object-cover'
        imgixParams={{
          fit: 'crop',
          crop: 'edges',
          ar: isMDScreen ? '2:1' : '5:2',
        }}
        src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questHomeId}/${questHome?.imageFileName}`}
        width={'100%'}
        alt='image'
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }}
        htmlAttributes={{
          src: `${process.env.REACT_APP_IMG_SOURCE}/quest/${questHomeId}/${
            questHome?.imageFileName
          }?ar=${
            isMDScreen ? '2:1' : '5:2'
          }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low-quality image
        }}
      />
      <div className='mt-24px'>
        <ModuleMiniDetails
          showDots={false}
          details={{
            label1: `${questHome?.month}`,
            label2: `${startDay}-${endDay},`,
            label3: `${questHome?.year}`,
          }}
        />
        <div className='s2 my-16px'>
          <span className='fst-italic'>{questHome?.title}</span>
        </div>
        <pre
          onClick={(e) => {
            e.stopPropagation()
            toggleExpand()
          }}
          className='s7 mb-0 pre'
          dangerouslySetInnerHTML={{ __html: getDescription() }}
        />
      </div>
    </>
  )
}

export default QuestDetail
