import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import TagManager from 'react-gtm-module'
import {
  FeatherLoader,
  plans,
  useErrorService,
  useUserService,
  useNotificationService,
} from '@abroad/components'
import RecentFavorites from './RecentFavorites'
import RecentlyPlayedAudio from './RecentlyPlayedAudio'
import AssessmentTakenModal from './AssessmentTakenModal'
import { ProgramsList } from '../breakthrough'
import HomeMiniSurveyResult from './HomeMiniSurveyResult'
import { GrayInfoCard, MediaBanner, UpgradeNow } from '../common'
import { MediaList } from '../media'
import API from '../../utils/API'
import { openLinkInTab } from '../../utils/openLinkInTab'
import { useResolutionService } from '../../hooks'
import HomeEvent from '../../constants/events'
import { surveyType } from '../../utils/constants'
import { QuestsHome } from '../quests'

const FreePlanHome = ({ surveyTaken, wisdomTaken }) => {
  if (!surveyTaken && !wisdomTaken) {
    return <FreePlanNoSurvey />
  } else {
    return <FreePlanWithSurvey />
  }
}

export const FreePlanNoSurvey = ({
  type = surveyType.AWARENESS,
  isRenderOnAwarenessPage = false,
  isRenderOnCustomHomePage = false,
  isQuestHomePage = false,
  wisdomPage,
}) => {
  const [link, setLink] = useState()
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isShowModal, setIsShowModal] = useState(false)
  const { isLGScreen, isMDScreen, isSMScreen } = useResolutionService()
  const Notification = useNotificationService()
  const { user } = useUserService()
  const isWisdomEnabled = user?.affiliation?.isWisdomEnabled
  const onClose = () => {
    setIsShowModal(false)
  }

  const imgCropFPObject = {
    // free plan user without survey
    'fp-x': 0.5,
    'fp-y': 0.48,
    'fp-z': 1.45,
    ...((isLGScreen || isMDScreen) && { ar: '21:9' }),
    ...(isSMScreen && {
      auto: 'format',
      fit: 'crop',
      crop: 'focalpoint',
      ar: '23:20',
      h: '441',
    }),
  }

  useEffect(() => {
    const getUrl = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.survey.getLink(
          isWisdomEnabled && !isRenderOnAwarenessPage
            ? surveyType.WISDOM
            : type === surveyType.WISDOM ||
              (!isRenderOnAwarenessPage && user?.workStatus === 'transition')
            ? surveyType.WISDOM
            : '',
        )
        setLink(data.link)
        setIsLoading(false)
        if (data?.code === 'upgrade_plan') {
          setError(data?.message)
        } else {
          setError(null)
        }
      } catch (e) {
        setIsLoading(false)
        if (e?.code === 'survey_limit_reached') {
          setError(e.message)
        }
      }
    }

    if (user) getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWisdomEnabled])

  const takeNewAssessment = () => {
    Notification.showNotification(error)
  }

  const gtmTakeAssessmentAction = () => {
    //GTM code
    TagManager.dataLayer({
      dataLayer: {
        event: HomeEvent.event.openAssessmentTypeform,
        eventProps: {
          category: HomeEvent.category.openAssessmentTypeformCategory,
          action: 'User open assessment typeform',
          label: 'Assessment typeform open by user',
          value: 'User open assessment typeform',
          userId: user?.id,
        },
      },
    })
  }

  const beginSurveyBanner = () => {
    return (
      <>
        <Col className='col-6 px-0'>
          <MediaBanner
            imageFilename={
              isQuestHomePage ? 'YourQuestWA.jpg' : 'freePlanWithoutSurvey.png'
            }
            imgCropFP={imgCropFPObject}
            isQuestHomePage={isQuestHomePage}
          />
        </Col>
        <Col className='col-6 bg-abroad-blue d-flex justify-content-center align-items-center begin-section px-0'>
          <div className='d-flex begin-journey-section w-100 justify-content-center'>
            <div className='text-white s9 mr-12px begin-text'>Begin</div>
            <span className='icon icon-right-arrow text-white' />
          </div>
        </Col>
      </>
    )
  }

  return (
    <div
      className={classNames({
        'mt-40px mb-40px': !isRenderOnCustomHomePage,
        'mt-32px mb-64px': isQuestHomePage,
      })}>
      <AssessmentTakenModal
        isShowModal={isShowModal}
        onClose={onClose}
        isOpenSixMonthsButtons={
          !isRenderOnAwarenessPage &&
          user?.companyId?.isWisdomEnabled &&
          user?.workStatus === 'operating'
        }
      />
      {!isQuestHomePage && !isRenderOnCustomHomePage && (
        <>
          <div className='s3 mb-12px'>
            {user?.companyId?._id === '65cfce26417a9ded11d06932'
              ? 'Welcome to Vanderbilt Coaching Circles'
              : `Your Journey Starts with Awareness`}
          </div>
          <div className='s5 mb-52px'>
            {user?.companyId?._id !== '65cfce26417a9ded11d06932' &&
              `Welcome to Abroad.`}{' '}
            To get started, please take our confidential{' '}
            <strong>15 minute Self Assessment.</strong> Please answer the
            questions honestly, as your learning, coaching, and overall
            experience will be shaped by the results of this assessment.
          </div>
        </>
      )}
      <Container fluid className='px-0'>
        <Row className='begin-page'>
          {(isRenderOnAwarenessPage &&
            user?.workStatus === 'transition' &&
            !wisdomPage) ||
          (!isRenderOnAwarenessPage &&
            user?.companyId?.isWisdomEnabled &&
            user?.workStatus === 'operating') ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              // eslint-disable-next-line no-script-url
              href='javascript:void(0)'
              className='text-white d-flex text-decoration-none begin-link'
              rel='noreferrer'
              onClick={(e) => {
                setIsShowModal(true)
              }}>
              {beginSurveyBanner()}
            </a>
          ) : (
            <>
              {error ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  className={classNames(
                    'text-white d-flex text-decoration-none begin-link',
                    {
                      'pointer-events-none': isLoading,
                    },
                  )}
                  // eslint-disable-next-line no-script-url
                  href='javascript:void(0)'
                  onClick={() => {
                    takeNewAssessment()
                  }}>
                  {beginSurveyBanner()}
                </a>
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  className={classNames(
                    'text-white d-flex text-decoration-none begin-link',
                    {
                      'pointer-events-none': isLoading,
                    },
                  )}
                  // eslint-disable-next-line no-script-url
                  href='javascript:void(0)'
                  onClick={() => {
                    openLinkInTab(link, '_self')
                    gtmTakeAssessmentAction()
                  }}>
                  {beginSurveyBanner()}
                </a>
              )}
            </>
          )}
        </Row>
      </Container>
    </div>
  )
}

const FreePlanWithSurvey = () => {
  const Error = useErrorService()

  const [threeLowestCatData, setThreeLowestCatData] = useState([])
  const [threeLowestCatLoader, setThreeLowestCatLoader] = useState(true)

  useEffect(() => {
    const getThreeLowestCategory = async () => {
      try {
        setThreeLowestCatLoader(true)
        const { data } = await API.mediaLibrary.getThreeLowestCategory()
        if (data) {
          setThreeLowestCatData(data)
        }
        setThreeLowestCatLoader(false)
      } catch (e) {
        setThreeLowestCatLoader(false)
        Error.showError(e)
      }
    }
    getThreeLowestCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (threeLowestCatLoader) {
    return (
      <div className='text-center m-3'>
        <FeatherLoader />
      </div>
    )
  }

  return (
    <div>
      <div className='mb-52px'>
        <QuestsHome />
      </div>

      <GrayInfoCard
        className='mb-52px mt-32px'
        text='Based on the results of your assessment, as part of your free plan, we
        have included some audios to help address your key areas for growth.'
      />
      {threeLowestCatData.map((category) => (
        <MediaList
          category={category}
          key={category.title}
          rowMarginBottom='mb-40px'
        />
      ))}

      <HomeMiniSurveyResult isQuestHomePage={true} />
      <div className='mb-52px'>
        <RecentlyPlayedAudio />
      </div>
      <div className='mb-52px'>
        <RecentFavorites />
      </div>
      <div className='mt-52px mb-52px'>
        <div className='s1 mb-3'>Unlock doors to unparalleled growth</div>
        <div className='s5'>
          Our signature programs, curated by industry-leading experts, offer
          bespoke modules that empower, enlighten, and evolve your leadership
          skills. Get a taste of what's in store with a glimpse into the
          introductory modules.
        </div>
        <ProgramsList />
      </div>

      <UpgradeNow
        wrapperClasses='my-4 border-radius-full'
        title='Ready to Unlock the Full Experience?'
        subTitle={`Dive into each program's expansive journey, filled with tailored modules designed to redefine your leadership prowess. Upgrade to the Coaching Plan and embark on an odyssey of purposeful growth.`}
        buttonClasses='leading-4'
        plan={plans.coaching.code}
        buttonText='Inquire about Coaching'
      />
    </div>
  )
}

export default FreePlanHome
