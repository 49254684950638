import React from 'react'

import RecentNotesAndLogInsight from './RecentNotesAndLogInsight'
import RecommendedAudio from './RecommendedAudio'
import RecentlyPlayedAudio from './RecentlyPlayedAudio'
import ContinueYourProgram from './ContinueYourProgram'
import RecentFavorites from './RecentFavorites'
import HomeMiniSurveyResult from './HomeMiniSurveyResult'
import QuestsHome from '../quests/QuestsHome'

// todo: shimmer effect in all program/media UIs
const CoachingPlanHome = () => {
  return (
    <div>
      <QuestsHome />
      <div className='mb-52px'>
        <ContinueYourProgram />
      </div>
      <RecentNotesAndLogInsight />
      <div className='mb-52px'>
        <RecommendedAudio />
      </div>
      <HomeMiniSurveyResult />
      <div className='mb-52px'>
        <RecentlyPlayedAudio />
      </div>
      <div className='mb-52px'>
        <RecentFavorites />
      </div>
    </div>
  )
}

export default CoachingPlanHome
