import React from 'react'
import { Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const QuestSection = ({ task }) => {
  const history = useHistory()

  return (
    <div onClick={task.onClick || (() => history.push(task.link))}>
      <div className='cursor-pointer py-16px d-flex align-items-center justify-content-between quest-border-color border-radius-10 mt-24px'>
        <div className='d-flex align-items-center'>
          <div className='pr-16px'>
            <Image
              src={task.imageUrl}
              className='border-radius-50 ms-4'
              style={{ height: '60px', width: '60px' }}
              alt='image'
            />
          </div>
          <div className='d-flex flex-column'>
            <div className='s6c ms-1 font-hover'>{task.title}</div>
            <h1 className='s11 ms-1 mt-2 font-saffron-700'>Review Now</h1>
          </div>
        </div>
        <div className='icon icon-right-arrow me-3 color-right-arrow s9'></div>
      </div>
    </div>
  )
}

export default QuestSection
