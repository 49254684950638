import React from 'react'
import Imgix from 'react-imgix'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/attrchange/ls.attrchange'

const MediaBanner = React.memo(
  ({
    imageFilename,
    imgCropFP,
    isLoading = false,
    isQuestHomePage = false,
  }) => {
    if (isLoading) {
      return (
        <div
          ref={(ref) => {
            if (ref) {
              let divider = 2.5
              ref.getElementsByClassName('shimmer')[0].style.height = `${
                ref.getElementsByClassName('shimmer')[0].offsetWidth / divider
              }px`
            }
          }}
          className='w-100 h-100'>
          <ShimmerThumbnail rounded className='border-radius-10' />
        </div>
      )
    }

    return (
      <div className='position-relative img-hover-zoom overflow-hidden'>
        <Imgix
          className='lazyload w-100 img-cover free-plan-without-survey-image h-auto'
          imgixParams={{
            fit: isQuestHomePage ? 'clip' : 'crop',
            crop: 'focalpoint',
            // ar: '2:1',
            ...imgCropFP,
          }}
          src={`${process.env.REACT_APP_IMG_SOURCE}/${imageFilename}`}
          alt='image'
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
          htmlAttributes={{
            src: isQuestHomePage
              ? `${
                  process.env.REACT_APP_IMG_SOURCE
                }/${imageFilename}?crop=focalpoint}${
                  imgCropFP &&
                  `&fp-x=${imgCropFP['fp-x']}&fp-y=${imgCropFP['fp-y']}&fp-z=${imgCropFP['fp-z']}`
                }&fit=clip&fill=blur&blur=300&px=16&auto=format`
              : `${
                  process.env.REACT_APP_IMG_SOURCE
                }/${imageFilename}?crop=focalpoint}${
                  imgCropFP &&
                  `&fp-x=${imgCropFP['fp-x']}&fp-y=${imgCropFP['fp-y']}&fp-z=${imgCropFP['fp-z']}`
                }&fit=crop&fill=blur&blur=300&px=16&auto=format`, // low quality image here
          }}
        />
      </div>
    )
  },
)

export default MediaBanner
